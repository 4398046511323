import { ProductList } from './components';
import { extractFromCamelCase } from 'prismic-react-tools';
import PropTypes from 'prop-types';
import React from 'react';
import withPrismicPage from '../../HOC/withPrismicPage';

const ProductAggregator = props => {
  const {
    pageContext: { document, productCategories, products }
  } = props;

  const { body: slices, files } = document;
  const sidebar = extractFromCamelCase(document, 'sidebar');
  const strings = extractFromCamelCase(document, 'strings');

  return <ProductList productCategories={productCategories} products={products} sidebar={sidebar} strings={strings} />;
};

ProductAggregator.propTypes = {
  pageContext: PropTypes.object
};

export default withPrismicPage(ProductAggregator);
