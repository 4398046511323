import { colors, fonts } from '../../../../theme';
import { mq } from 'styled-gen';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

const ProductCard = styled.div`
  background-color: ${colors.n01};
  border-radius: 0.25rem;
  box-shadow: 0 0 2rem ${rgba(colors.n08, 0.16)};
  display: flex;
  flex-direction: column;
  padding: 2.5rem;

  & > p {
    width: 100%;
  }

  & + & {
    margin-top: 6rem;
  }

  ${mq.tablet(css`
    flex-direction: row;
  `)};
`;

const ProductCardImage = styled.div`
  background-image: url(${({ bkgImage }) => bkgImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;
  padding-top: 100%;
  width: 100%;

  ${mq.tablet(css`
    height: 100%;
    padding-top: 40%;
    width: 15rem;
  `)}
`;

const ProductListLink = styled.a`
  align-items: center;
  background-color: ${({ isActive }) => colors[isActive ? 'n04' : 'n01']};
  color: ${({ isActive }) => colors[isActive ? 'n01' : 'n06']};
  border: 1px solid ${colors.n04};
  border-radius: 1rem;
  display: inline-flex;
  height: 2rem;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;

  & + & {
    margin-top: 1rem;
  }

  ${mq.tablet(css`
    & + & {
      margin-left: 1rem;
      margin-top: 0;
    }
  `)}

  ${mq.tabletLandscape(css`
    background-color: transparent;
    border: none;
    border-radius: 0;
    color: ${colors.n06};
    cursor: default;
    display: flex;
    height: 2.75rem;
    justify-content: space-between;
    max-width: 16.5rem;
    padding-left: 2.5rem;
    position: relative;
    width: 100%;

    & + & {
      margin-left: 0;
    }

    &::before {
      background-color: ${({ isActive }) => colors[isActive ? 'p06' : 'n03']};
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      width: 0.25rem;
    }

    ${({ isActive }) =>
      !isActive
        ? css`
            &:hover {
              background-color: ${colors.n02};
            }
          `
        : css`
            cursor: default !important;
          `}
  `)}
`;

const ProductListLinkCount = styled.div`
  align-items: center;
  background-color: ${colors.n06};
  border-radius: 50%;
  color: ${colors.n01};
  display: flex;
  font-size: 0.625rem;
  font-weight: ${fonts.weights.bold};
  height: 1.25rem;
  margin-left: 0.5rem;
  justify-content: center;
  width: 1.25rem;

  ${mq.tabletLandscape(css`
    margin-left: 0;
  `)};
`;

export { ProductCard, ProductCardImage, ProductListLink, ProductListLinkCount };
