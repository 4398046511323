import { Button, Col, Div, DocumentStyle, Grid, Row, Section } from '../../../../theme/components';
import { Heading, Text } from '../../../../theme/components/Typography';
import { ProductCard, ProductCardImage, ProductListLink, ProductListLinkCount } from './ProductList.style';
import { RichText } from 'prismic-react-tools';
import { SectionContent } from '../../../../components';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import queryString from 'query-string';

export const ProductList = props => {
  let location;

  if (typeof window !== 'undefined') {
    location = window?.location;
  }

  const search = location?.search;
  const { category: initialCategory } = queryString.parse(search) || {};
  const { productCategories, products, sidebar, strings } = props;
  const { filters, ...sectionContent } = sidebar;
  const { allProducts, cardButtonLabel, comingSoonButtonLabel, productComingSoon } = strings;

  const [categories, setCategories] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(initialCategory);

  useEffect(() => {
    const categoryCollection = productCategories.reduce(
      (previous, { uid, label }) => ({ ...previous, [uid]: { label, products: [] } }),
      {}
    );

    products.forEach(({ category: { uid }, uid: productId }) => categoryCollection[uid].products.push(productId));

    setCategories(categoryCollection);
  }, []);

  useEffect(() => {
    let location;

    if (typeof window !== 'undefined') {
      location = window?.location;
    }

    const search = location?.search;
    const query = queryString.parse(search);

    query.category = selectedCategory;

    const searchString = queryString.stringify(query);

    window.history.replaceState(null, null, searchString ? `?${searchString}` : '');
  }, [selectedCategory]);

  const sortProducts = products =>
    productCategories.reduce(
      (acc, { uid }) => [...acc, ...products.filter(product => product.category.slug === uid)],
      []
    );

  if (!categories) {
    return null;
  }

  return (
    <Section mb={{ md: -8, xs: -4 }} style={{ minHeight: 800, position: 'relative', zIndex: 100 }}>
      <Grid>
        <Row>
          <Col md={4} xs={12}>
            <SectionContent {...sectionContent} />
            <Div
              flex={{ md: 'center flex-start', sm: 'center center false row', xs: 'center center true column' }}
              mt={2.75}
              style={{ width: '100%' }}
              textAlign={{ md: 'left', xs: 'center' }}
            >
              <ProductListLink isActive={!selectedCategory} onClick={() => setSelectedCategory(null)}>
                {allProducts}
              </ProductListLink>
              {filters.map(({ category: { uid } }) => (
                <ProductListLink isActive={selectedCategory === uid} key={uid} onClick={() => setSelectedCategory(uid)}>
                  {categories[uid].label}
                  <ProductListLinkCount>{categories[uid].products.length}</ProductListLinkCount>
                </ProductListLink>
              ))}
            </Div>
          </Col>
          <Col md={7} mdOffset={1} mt={{ md: 0, xs: 3 }} xs={12}>
            {sortProducts(products).map(
              ({
                category: { uid: categoryId },
                heroDescription,
                heroHeading,
                heroImage: { url: bkgImage },
                isLive,
                uid
              }) =>
                categoryId === selectedCategory || !selectedCategory ? (
                  <ProductCard key={uid}>
                    <ProductCardImage bkgImage={bkgImage} />
                    <Div ml={1.5} mt={{ sm: 0, xs: 2 }}>
                      <Heading centeredOnMobile h4 semibold>
                        {heroHeading}
                      </Heading>
                      <Text as="div" centeredOnMobile mt={1.5}>
                        <DocumentStyle>
                          <RichText content={heroDescription} />
                        </DocumentStyle>
                        {isLive === 'yes' ? (
                          <Button href={`/products/${uid}`} mt={2}>
                            {cardButtonLabel}
                          </Button>
                        ) : (
                          <Button
                            dark
                            href={`mailto:info@hydrustent.com?subject=Product interest: ${heroHeading}`}
                            mt={2}
                          >
                            {comingSoonButtonLabel}
                          </Button>
                        )}
                      </Text>
                    </Div>
                  </ProductCard>
                ) : null
            )}
            <ProductCard>
              <Text center large n04 semibold>
                {productComingSoon}
              </Text>
            </ProductCard>
          </Col>
        </Row>
      </Grid>
    </Section>
  );
};

ProductList.propTypes = {
  productCategories: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      uid: PropTypes.string
    })
  ),
  products: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.shape({
        uid: PropTypes.string
      }),
      heroDescription: PropTypes.any,
      heroHeading: PropTypes.string,
      heroImage: PropTypes.shape({
        url: PropTypes.string
      }),
      isLive: PropTypes.oneOf(['yes', 'no']),
      uid: PropTypes.string
    })
  ),
  sidebar: PropTypes.shape({
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        category: PropTypes.shape({
          uid: PropTypes.string
        })
      })
    ),
    preHeading: PropTypes.string,
    text: PropTypes.any
  }),
  strings: PropTypes.shape({
    allProducts: PropTypes.string,
    cardButtonLabel: PropTypes.string,
    comingSoonButtonLabel: PropTypes.string,
    productComingSoon: PropTypes.string
  })
};
